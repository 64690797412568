
import { Vue, Component, Prop } from 'vue-property-decorator';
import truncate from 'lodash/truncate';
import DOMPurify from 'dompurify';
import { convertDateTime } from '@/utils/time';
import TablePagination from '@/components/TablePagination.vue';
import { NotificationsObject, Notification, ApprovalType } from '@/types/notifications.types';
import { EnumsModule } from '../store/modules/enums.module';
import { RouteNames } from '@/constants';
@Component({
  components: {
    TablePagination,
  },
})
export default class NotificationTable extends Vue {
  @Prop({ type: Object, required: false })
  data!: NotificationsObject;
  @Prop({ type: Number, required: true }) page!: number;
  @Prop({ type: Number, required: true }) results!: number;
  @Prop({ type: Number, required: true }) totalResults!: number;
  @Prop(Boolean) isRequests!: boolean;

  isUnread(notification: Notification) {
    return notification.statusId === EnumsModule.enums.status.NOTIFICATIONUNREAD.id;
  }

  get hasResult() {
    return this.data.notifications.length > 0;
  }

  icon(approvalType: ApprovalType): string {
    const lookup: Record<ApprovalType, string> = {
      APISYSTEMADMINAPPROVAL: 'D_curve_manual',
      APIOWNERAPPROVAL: 'insert',
      LISTINGTYPEBUSINESSUNITAPPROVAL: 'document_text',
      RISKAPPROVAL: 'bacteria_covid',
      BOTPROMOTIONAPPROVAALTYPE: 'bot',
    };
    return lookup[approvalType] ?? 'chart_relationship';
  }

  public get hideBack(): boolean {
    return this.page < 2;
  }

  public get hideForward(): boolean {
    const maxPage = this.totalResults / this.results;
    return (this.page >= maxPage);
  }

  formatMessage(message: string) {
    // using DOMPurify to remove all html tags for preview text
    let formattedMsg = DOMPurify.sanitize(message, { ALLOWED_TAGS: [] });
    return truncate(formattedMsg, {
      length: 90,
    });
  }

  convertDateTime(dateTime: string) {
    return convertDateTime(dateTime);
  }

  onNotificationClick(notification: Notification) {
    this.$router.push({
      name: RouteNames.NotificationDetails,
      params: { id: String(notification.notificationId) },
    });
  }
}
